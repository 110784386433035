import { defaultDevtoolsOptions } from '@/config/constants';
import { EnvConfig } from '@/types/common.types';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export interface EnvConfigParams extends EnvConfig {
  country: string;
  defaultCountry?: string;
  authorizedCountries: string[];
}

const initialState: EnvConfigParams = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  supportedCountries: [],
  SEGMENT_KEY: '',
  vendorId: {},
  optimizely_key: '',
  defaultCountry: '',
  country: '',
  authorizedCountries: [],
  BEARER_TOKEN: '',
};

export interface IEnvStore extends EnvConfigParams {
  setEnv: (env: Partial<EnvConfigParams>) => void;
  setCountry: (country: EnvConfigParams['country']) => void;
  setAuthorizedCountry: (authorizedCountries: EnvConfigParams['authorizedCountries']) => void;
}

const persistOptions = {
  name: 'couponEnvValues',
  storage: createJSONStorage(() => localStorage),
};

export const useEnvStore = create<IEnvStore>()(
  persist(
    devtools(
      (set, get) => ({
        ...initialState,
        setEnv: (env) => set({ ...get(), ...env, country: get().country || env.defaultCountry }),
        setCountry: (country) => set({ country }),
        setAuthorizedCountry: (authorizedCountries) => set({ authorizedCountries }),
      }),
      { ...defaultDevtoolsOptions, anonymousActionType: 'useEnvStore' }
    ),
    persistOptions
  )
);
