import permissions from '@/config/permissions';
import { useEnvStore } from '@/stores/useEnvStore/useEnvStore';
import { EnvConfig } from '@/types/common.types';
import { useAuthenticationService, useHasPermission } from 'admin-portal-shared-services';
import { useEffect } from 'react';

const useLoadEnvs = (envs: EnvConfig, defaultCountry: string) => {
  const { supportedCountries } = envs;

  const { setEnv, setAuthorizedCountry } = useEnvStore(({ setEnv, setAuthorizedCountry }) => ({
    setEnv,
    setAuthorizedCountry,
  }));

  //Set envs
  useEffect(() => {
    const bearerToken = localStorage.getItem('authHeader') ?? '';
    setEnv({ ...envs, defaultCountry, BEARER_TOKEN: bearerToken });
  }, [defaultCountry]);

  //Set Authorized countries
  const isBeesAdmin = useHasPermission(permissions.BEES_ADMIN);
  const isBeesViewer = useHasPermission(permissions.BEES_VIEWER);
  const authentication = useAuthenticationService();

  const hasPermission = isBeesAdmin || isBeesViewer;

  useEffect(() => {
    if (supportedCountries.length === 0 || !authentication) return;

    if (hasPermission) {
      setAuthorizedCountry(supportedCountries);
      return;
    }

    const userSupportedCountries = authentication.getSupportedCountries();
    const filteredCountries = supportedCountries.filter((country) =>
      userSupportedCountries.includes(country)
    );
    setAuthorizedCountry(filteredCountries);
  }, [supportedCountries, authentication]);
};

export default useLoadEnvs;
