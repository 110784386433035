import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@hexa-ui/theme';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { BrowserRouter } from 'react-router-dom';
import { getApolloClient } from './Api/graphql/graphql';
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from './config/featureToggles';
import useLoadEnvs from './hooks/useLoadEnvs/useLoadEnvs';
import useSegmentAnalytics from './hooks/useSegmentAnalytics/useSegmentAnalytics';
import { IntlProvider } from './i18n';
import { EnvConfig } from './types/common.types';
import AppBarConfig from './utils/AppBarConfig';
import { getInitialCountry } from './utils/AppUtils';

export default function App(props: Readonly<EnvConfig>) {
  const { optimizely_key } = props;
  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizely_key || '');
  useSegmentAnalytics(props.SEGMENT_KEY);
  const country = getInitialCountry(props.supportedCountries);
  useLoadEnvs(props, country);
  const client = getApolloClient(country);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme="tadaPrimary">
        <IntlProvider>
          <BrowserRouter>
            <AppBarConfig />
          </BrowserRouter>
        </IntlProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
